var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('EditContactInformationModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem,
      "existingItemId": _vm.modalData.existingItemId,
      "elementId": _vm.modalData.existingItemId,
      "defaultValues": _vm.modalData.defaultValues,
      "courseId": _vm.courseId
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal();
      }
    }
  })], 1) : _vm._e(), _vm.personInfo ? _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.personInfo.customer.firstName) + " " + _vm._s(_vm.personInfo.customer.lastName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "ml-auto",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openEditPersonInfo({
                id: _vm.personId
              });
            }
          }
        }, [_c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Rediger kontaktinformasjon ")], 1)])];
      },
      proxy: true
    }], null, false, 1510327829)
  }, [[_c('ParticipantGeneralInfo', {
    attrs: {
      "contact": _vm.personInfo
    }
  })]], 2) : _vm._e(), _vm.participantStatuses && _vm.participantStatuses.length ? _c('BaseLayout', {
    staticClass: "pa-0 pt-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Historikk")];
      },
      proxy: true
    }], null, false, 3760563279)
  }, [[_c('ParticipantStatusTable', {
    attrs: {
      "participantStatuses": _vm.participantStatuses
    }
  })]], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }