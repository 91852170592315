var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.participantStatuses,
      "showTitle": false,
      "sort-by": "startDate",
      "sort-desc": true,
      "item-key": "courseId"
    },
    scopedSlots: _vm._u([{
      key: "item.startDate",
      fn: function fn(_ref) {
        var _ref$item = _ref.item,
            startDate = _ref$item.startDate,
            endDate = _ref$item.endDate;
        return [_c('div', [_vm._v(_vm._s(_vm.getFormattedDatePeriod(startDate, endDate)))])];
      }
    }, {
      key: "item.roleName",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_c('div', [_vm._v(_vm._s(_vm.getRole(value)))])];
      }
    }, {
      key: "actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "data-cy": "displayCourseDashboard",
                  "to": _vm.toCourseDashboard(item.courseId)
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Gå til kursdashbord")])])];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }